import {queryIZNewsCategories, queryIZNewsWidgets} from "./news.query";
import {typeQueryOptions} from "../../../shared/base.query";
import {useIZNewsUtils} from "./useIZNewsUtils";
import {TypeNewsCategoryDto, TypeNewsDto} from "./news.type";

export const useIZNewsWidgets = () => {

  const globalSettings = inject("globalSettings");
  const {getItems} = useDirectusItems();
  const {generateNewsDto, generateNewsCategoryDto, logger} = useIZNewsUtils(globalSettings);

  const getOnTop = async (languageCode: string, options?: {
    category_id?: string | number,
    query?: typeQueryOptions
  }) => {
    let query = queryIZNewsWidgets({
      filter: {
        _and: [
          {
            status: {
              _eq: "published"
            }
          },
          {
            _or: [
              {
                hero: {
                  _eq: true
                }
              }
              ,
              {
                on_top: {
                  _eq: true
                }
              }
            ]
          }
        ]
      },
      sort: ["-hero", "-on_top", "-date_created"],
      limit: options?.query?.limit || -1,
    });

    if (options && options.category_id) {

      const filter = {
        _and: [
          {
            status: {
              _eq: "published"
            }
          },
          {
            on_top: {
              _eq: true
            }
          },
          {
            category: {
              _eq: options.category_id
            }
          }
        ]
      }
      query.params.filter = {
        ...query.params.filter,
        ...filter
      }
    }

    return getItems({
      ...query
    }).then((data: any) => {
      return data.map((ite: any) => {
        return generateNewsDto(ite, languageCode) as TypeNewsDto;
      })
    }).catch(e => {
      logger.error("Error: getLatestNews!");
      logger.debug(e);
      return {};
    })
  }

  const getFeaturedCategories = async (languageCode: string, options: any) => {

    let query = queryIZNewsCategories({
      filter: {
        _and: [
          {
            status: {
              _eq: "published"
            }
          }, {
            show: {
              _eq: true
            }
          }]
      },
      sort: ["sort"],
      limit: options.limit || -1
    });
    return getItems({
      ...query
    }).then(
      (res: any) => {
        return res.map((ite: any) => {
          return generateNewsCategoryDto(ite, languageCode) as TypeNewsCategoryDto;
        })
      }).catch((e: any) => {
      logger.error("Error: getFeaturedCategories!");
      logger.debug(e);
      return [];
    })
  }

  const getRelatedNews = async (news_id: string | number, category_id: string | number, languageCode: string, options: typeQueryOptions) => {

    let query = queryIZNewsWidgets({
      filter: {
        _and: [
          {
            status: {
              _eq: "published"
            },

          },
          {
            id: {
              _neq: +news_id,
            }
          },
          {
            category: {
              _eq: +category_id
            }
          }
        ]
      },
      sort: ["-hero", "-on_top", "-date_created"],
      limit: options.limit || 6,
      ...options
    });

    return getItems({
      ...query
    })
      .then((res: any) => {
        return res.map((ite: any) => {
          return generateNewsDto(ite, languageCode) as TypeNewsDto;
        });
      }).catch((e: any) => {
        logger.error("Error: getRelated!");
        logger.debug(e);
        return [];
      })
  }


  const getLatestNewsByCategory = async (id: string | Number, langCode: string, options?: {
    query?: {
      limit?: any,
      page?: string | number
    }
  }) => {

    let query = queryIZNewsWidgets({
      filter: {
        _and: [
          {
            status: {
              _eq: "published"
            }
          },
          {
            category: {
              _eq: +id
            }
          }
        ]
      },
      sort: ["-hero", "-on_top", "-date_created"],
      limit: options?.query?.limit || 6,
    });

    const data = await getItems({
      ...query
    })
      .then((res: any) => {
        return res.map((ite: any) => {
          return generateNewsDto(ite, langCode) as TypeNewsDto;
        })

      }).catch((e: any) => {
        logger.error("Error: getLatest!");
        logger.debug(e);
        return [];
      })
    return data
  }

  return {
    getOnTop,
    getRelatedNews,
    getFeaturedCategories,
    getLatestNewsByCategory,
    logger
  }
}
